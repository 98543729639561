// Generated by ReScript, PLEASE EDIT WITH CARE


function loginReducer(state, action) {
  if (typeof action === "number") {
    return {
            email: "",
            password: "",
            alert: undefined
          };
  }
  switch (action.TAG | 0) {
    case /* ChangeEmail */0 :
        return {
                email: action._0,
                password: state.password,
                alert: state.alert
              };
    case /* ChangePassword */1 :
        return {
                email: state.email,
                password: action._0,
                alert: state.alert
              };
    case /* SetAlert */2 :
        return {
                email: state.email,
                password: state.password,
                alert: action._0
              };
    
  }
}

function registrationReducer(state, action) {
  if (typeof action === "number") {
    return {
            email: "",
            password: "",
            secondPassword: "",
            alert: undefined,
            success: undefined
          };
  }
  switch (action.TAG | 0) {
    case /* ChangeEmail */0 :
        return {
                email: action._0,
                password: state.password,
                secondPassword: state.secondPassword,
                alert: state.alert,
                success: state.success
              };
    case /* ChangePassword */1 :
        return {
                email: state.email,
                password: action._0,
                secondPassword: state.secondPassword,
                alert: state.alert,
                success: state.success
              };
    case /* ChangeSecondPassword */2 :
        return {
                email: state.email,
                password: state.password,
                secondPassword: action._0,
                alert: state.alert,
                success: state.success
              };
    case /* SetAlert */3 :
        return {
                email: state.email,
                password: state.password,
                secondPassword: state.secondPassword,
                alert: action._0,
                success: state.success
              };
    case /* Success */4 :
        return {
                email: "",
                password: "",
                secondPassword: "",
                alert: undefined,
                success: action._0
              };
    
  }
}

function resetReducer(state, action) {
  if (typeof action === "number") {
    return {
            email: "",
            alert: undefined,
            success: undefined
          };
  }
  switch (action.TAG | 0) {
    case /* ChangeEmail */0 :
        return {
                email: action._0,
                alert: state.alert,
                success: state.success
              };
    case /* SetAlert */1 :
        return {
                email: state.email,
                alert: action._0,
                success: state.success
              };
    case /* Success */2 :
        return {
                email: "",
                alert: undefined,
                success: action._0
              };
    
  }
}

function strongPassword(password) {
  var regex = new RegExp("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})");
  return regex.test(password);
}

function samePassword(password1, password2) {
  return password2.startsWith(password1);
}

export {
  loginReducer ,
  registrationReducer ,
  resetReducer ,
  strongPassword ,
  samePassword ,
  
}
/* No side effect */
