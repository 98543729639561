// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SupabaseJs from "@supabase/supabase-js";

var supabase = SupabaseJs.createClient(process.env.REACT_APP_SUPABASE_URL, process.env.REACT_APP_SUPABASE_ANON_KEY);

export {
  supabase ,
  
}
/* supabase Not a pure module */
